<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="MonitorIcon"
        size="25"
      />Sale Riunioni {{ headquarter_name }}</h1>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
              <!-- <b-button variant="primary" class="text-nowrap" :to="{ name: 'apps-meetingroomsreception-add' }"
                >Nuova Sala Riunioni</b-button
              > -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMeetingroomsListTable"
        class="position-relative"
        :items="fetchMeetingrooms"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Sala Riunioni corrispondente trovata"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-link :to="{ name: 'apps-meetingroomsreception-view', params: { id: data.item.id } }">
                <b-img
                  :src="data.item.image_path ? mediaUrl + data.item.image_path : ''"
                  v-bind="data.item.image_path ? mainProps : blankProps"
                  rounded
                  alt="Immagine Sala Riunioni"
                  class="meetingroom_list_img d-inline-block"
                />
              </b-link>
            </template>
            <b-link
              :to="{ name: 'apps-meetingroomsreception-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Posti -->
        <template #cell(posti)="data">
          <div class="text-nowrap">
            <feather-icon
              class="mr-1 text-info"
              icon="UserIcon"
            />
            <span class="align-text-top text-capitalize">{{ data.item.max_persons }}</span>
          </div>
        </template>

        <template #cell(sede)="data">
          <div
            v-if="data.item.headquarter"
            class="text-nowrap"
          >
            <b-link>
              <feather-icon
                class="mr-1 text-primary"
                icon="MapPinIcon"
              />
              <span
                class="align-text-top text-capitalize"
              >#{{ data.item.headquarter.id }} - {{ data.item.headquarter.name }}</span>
            </b-link>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-meetingroomsreception-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-meetingroomsreception-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifica</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedMeetingroom = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMeetingrooms"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="delete-modal"
        title="Cancella Sala Riunioni"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Cancella Sala Riunioni
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler cancellare definitivamente questa Sala Riunioni e i suoi dati correlati?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteMeetingroom(selectedMeetingroom)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BMedia,
  BLink,
  BImg,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useMeetingroomsList from './useMeetingroomsList'
import meetingroomsStoreModule from '../meetingroomsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BLink,
    BImg,
    BModal,

    vSelect,
  },
  setup() {
    const MEETINGROOMS_APP_STORE_MODULE_NAME = 'app-meetingrooms'

    // Register module
    if (!store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MEETINGROOMS_APP_STORE_MODULE_NAME, meetingroomsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(MEETINGROOMS_APP_STORE_MODULE_NAME)
      }
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const headquarter_name = localStorage.getItem('headquarter_name')

    const {
      fetchMeetingrooms,
      tableColumns,
      perPage,
      currentPage,
      totalMeetingrooms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMeetingroomsListTable,
      refetchData,
    } = useMeetingroomsList()

    return {
      fetchMeetingrooms,
      tableColumns,
      perPage,
      currentPage,
      totalMeetingrooms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMeetingroomsListTable,
      refetchData,

      // Filter
      avatarText,
      mainProps: {
        width: 100,
        height: 50,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        width: 100,
        height: 50,
      },
      selectedMeetingroom: null,
      mediaUrl,
      headquarter_name,
    }
  },
  methods: {
    deleteMeetingroom(id) {
      store
        .dispatch('app-meetingrooms/deleteMeetingroom', { id })
        .then(() => {
          this.selectedMeetingroom = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Sala Riunioni #${id} eliminata con successo`,
              icon: 'MonitorIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.media-body {
  align-self: center;
}
.meetingroom_list_img {
  object-fit: cover;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
